var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pathadvice-lead-form pathadvice-lead-form--mobile",
      class: {
        "pathadvice-lead-form--chat": _vm.isInChat,
      },
      style: {
        "--contact-form-font-color": _vm.dialog.contactForm.fontColor,
        "--contact-form-background": _vm.dialog.contactForm
          .isDefaultContactFormInsideChat
          ? "#EFF1F5"
          : _vm.toolBarBackground,
        "--contact-form-darken-background":
          _vm.darkСontactFormSendButtonBackgroundColor,
        "--contact-form-dark-background":
          _vm.darkСontactFormSendButtonBackgroundColor,
      },
    },
    [
      !_vm.isInChat
        ? [
            _c(
              "button",
              {
                staticClass: "pathadvice__button-back",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.goBackToCreateNewRequest.apply(null, arguments)
                  },
                },
              },
              [
                _c("ArrowIcon", {
                  attrs: { color: "#fff" },
                  on: { click: _vm.goBackToCreateNewRequest },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "pathadvice-lead-form__title",
                style:
                  "color: " +
                  (_vm.dialog.contactForm.isDefaultContactFormInsideChat
                    ? "#262629"
                    : "#fff"),
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.$tp("vue.leadFormTitle")) + "\n    "
                ),
              ]
            ),
          ]
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "pathadvice-lead-form__description",
          style:
            "color: " +
            (_vm.dialog.contactForm.isDefaultContactFormInsideChat
              ? "#262629"
              : "#fff"),
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.isInChat
                  ? _vm.$tp("vue.leadFormTextInChat")
                  : _vm.$tp("vue.leadFormText")
              ) +
              "\n  "
          ),
        ]
      ),
      _c("div", { staticClass: "pathadvice-lead-form__fields" }, [
        _c("div", [
          _vm.isNameEnabled
            ? _c(
                "div",
                {
                  staticClass:
                    "pathadvice__contact-form__main-container__form__field",
                  class: {
                    "pathadvice__contact-form__main-container__form__field_invalid":
                      _vm.errors.first("name"),
                  },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.name,
                        expression: "name",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          required: _vm.isNameRequired,
                          max: 80,
                        },
                        expression:
                          "{\n            required: isNameRequired,\n            max: 80\n          }",
                      },
                    ],
                    key: "name",
                    staticClass:
                      "pathadvice__contact-form__main-container__form__input",
                    style:
                      "background: " +
                      (_vm.dialog.contactForm.isDefaultContactFormInsideChat
                        ? "#CFD2D7"
                        : "rgba(230, 236, 241, 0.6)"),
                    attrs: {
                      name: "name",
                      placeholder: _vm._f("modifyInputPlaceholder")(
                        _vm.$tp("buttonAndHints.name"),
                        _vm.isNameRequired
                      ),
                    },
                    domProps: { value: _vm.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.name = $event.target.value
                      },
                    },
                  }),
                  _vm.errors.first("name")
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "pathadvice__contact-form__main-container__form__field__error-checkbox",
                          style:
                            "color: " +
                            (_vm.dialog.contactForm
                              .isDefaultContactFormInsideChat
                              ? "#262629"
                              : "#fff"),
                        },
                        [
                          _vm._v(
                            "\n          * " +
                              _vm._s(_vm.errors.first("name")) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("UserIcon", {
                    staticClass:
                      "pathadvice__contact-form__main-container__form__field__icon",
                    attrs: { color: "#262629" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "pathadvice__contact-form__main-container__form__field",
              class: {
                "pathadvice__contact-form__main-container__form__field_invalid":
                  _vm.errors.first("email"),
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      required: true,
                      email: true,
                    },
                    expression:
                      "{\n            required: true,\n            email: true\n          }",
                  },
                ],
                key: "email",
                staticClass:
                  "pathadvice__contact-form__main-container__form__input",
                style:
                  "background: " +
                  (_vm.dialog.contactForm.isDefaultContactFormInsideChat
                    ? "#CFD2D7"
                    : "rgba(230, 236, 241, 0.6)"),
                attrs: {
                  name: "email",
                  placeholder: _vm._f("modifyInputPlaceholder")(
                    _vm.$tp("buttonAndHints.emailAddress"),
                    true
                  ),
                },
                domProps: { value: _vm.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  },
                },
              }),
              _vm.errors.first("email")
                ? _c(
                    "p",
                    {
                      staticClass:
                        "pathadvice__contact-form__main-container__form__field__error",
                      style:
                        "color: " +
                        (_vm.dialog.contactForm.isDefaultContactFormInsideChat
                          ? "#262629"
                          : "#fff"),
                    },
                    [
                      _vm._v(
                        "\n          * " +
                          _vm._s(_vm.errors.first("email")) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
              _c("MailIcon", {
                staticClass:
                  "pathadvice__contact-form__main-container__form__field__icon",
                attrs: { color: "#262629" },
              }),
            ],
            1
          ),
          _vm.leadForm && _vm.leadForm.isPrivacyInformationEnabled
            ? _c(
                "div",
                {
                  staticClass:
                    "mb-5 pathadvice__contact-form__main-container__form__field",
                  class: {
                    "pathadvice__contact-form__main-container__form__field_invalid":
                      _vm.errors.first("privacy-agreement"),
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex flex-row",
                      class: {
                        "pathadvice__contact-form__privacy-policy pt-0":
                          _vm.errors.first("privacy-agreement"),
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("vs-checkbox", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required:true",
                                expression: "'required:true'",
                              },
                            ],
                            key: "privacy-agreement",
                            staticClass:
                              "pathadvice__contact-form__privacy-policy__checkbox",
                            style: {
                              "--contact-form-darken-background":
                                _vm.darkСontactFormSendButtonBackgroundColor,
                              "--contact-form-font-color":
                                _vm.darkСontactFormSendButtonBackgroundColor,
                            },
                            attrs: {
                              name: "privacy-agreement",
                              type: "checkbox",
                            },
                            model: {
                              value: _vm.hasAgreedToPrivacy,
                              callback: function ($$v) {
                                _vm.hasAgreedToPrivacy = $$v
                              },
                              expression: "hasAgreedToPrivacy",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", [
                        _c("span", {
                          style:
                            "color: " +
                            (_vm.dialog.contactForm
                              .isDefaultContactFormInsideChat
                              ? "#262629"
                              : "#fff"),
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.leadForm.privacyInformationHtml
                            ),
                          },
                        }),
                      ]),
                    ]
                  ),
                  _vm.errors.first("privacy-agreement")
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "pathadvice__contact-form__main-container__form__field__error-checkbox",
                          style:
                            "color: " +
                            (_vm.dialog.contactForm
                              .isDefaultContactFormInsideChat
                              ? "#262629"
                              : "#fff"),
                        },
                        [
                          _vm._v(
                            "\n          * " +
                              _vm._s(_vm.errors.first("privacy-agreement")) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          {
            staticClass:
              "pathadvice__contact-form__main-container__form__actions pathadvice__contact-form__main-container__form__footer",
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "pathadvice__contact-form__main-container__form__actions__btn",
                style:
                  "background: " +
                  (_vm.dialog.contactForm.isDefaultContactFormInsideChat
                    ? "#494A4E"
                    : _vm.darkСontactFormSendButtonBackgroundColor) +
                  "; color: #fff !important",
                attrs: { disabled: _vm.isLoadingSubmit },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.isInChat
                        ? _vm.$tp("vue.send")
                        : _vm.$tp("vue.startConversation")
                    ) +
                    "\n      "
                ),
              ]
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }